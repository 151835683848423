import {mapActions} from 'vuex';
import CompanyService from '@/services/company.service';

const companyService = new CompanyService();

export default {
  name: "LeadRulesSettings",

  data() {
    return {
      followUpRuleTypes: ['CALL', 'EMAIL', 'SMS'],

      followUpActions: {
        enabled: true,
        Priority: [
          {actions: ['CALL']},
          {actions: ['SMS', 'EMAIL']}
        ]
      },

      incomingLeadsRules: {
        FollowUpActions: {enabled: false, Priority: []},
        Seller: {enabled: false, MinCalls: 0, TimePerCall: 0},
        BDC: {enabled: false, MinCalls: 0, TimePerCall: 0},
      },
    }
  },

  async mounted() {
    await this.getCompanySettings();
  },

  methods: {
    ...mapActions(['setCompanySettings']),

    async getCompanySettings() {
      const res = await companyService.getSettings();
      if (res && !res.error) {
        const {IncomingLeadsRules} = res.data;

        if (IncomingLeadsRules) {
          this.incomingLeadsRules = IncomingLeadsRules;
        }
      }
    },

    async editCompany() {
      const {CompanySettings} = this.$getCompany();
      this.incomingLeadsRules.FollowUpActions = JSON.parse(JSON.stringify(this.followUpActions));

      if (!this.followUpActions.enabled) {
        this.incomingLeadsRules.FollowUpActions.Priority = [];
      }

      await companyService.editLeadRules(CompanySettings._id, this.incomingLeadsRules);

      CompanySettings.IncomingLeadsRules = this.incomingLeadsRules;
      await this.setCompanySettings(CompanySettings);
    }
  }
};